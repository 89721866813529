import { render, staticRenderFns } from "./Viewer.vue?vue&type=template&id=adc0d644&scoped=true"
import script from "./Viewer.vue?vue&type=script&lang=js"
export * from "./Viewer.vue?vue&type=script&lang=js"
import style0 from "./Viewer.vue?vue&type=style&index=0&id=adc0d644&prod&rel=stylesheet%2Fscss&lang=scss&scoped=true"
import style1 from "./Viewer.vue?vue&type=style&index=1&id=adc0d644&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "adc0d644",
  null
  
)

export default component.exports