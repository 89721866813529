<template>
  <div>
    <v-row class="mb-4 d-flex justify-center">
      <v-col cols="auto">
        <v-icon class="mr-1 white--text" @click="before()">navigate_before</v-icon>
         <input v-model.number="page" type="text" class="pdf-page-input mr-1" />
         <span class="white--text px-2">/</span>
         <span class="white--text px-2">{{ numPages }}</span>
          <v-icon class="mr-1 white--text" @click="next()">navigate_next</v-icon>
      </v-col>
      <v-col cols="auto">
        <v-icon class="mr-1 white--text" @click="rotate += 90">rotate_right</v-icon>
      </v-col>
      <v-col cols="auto">
        <v-icon class="mr-1 white--text" @click="rotate -= 90">rotate_left</v-icon>
      </v-col>
      <v-col cols="auto" v-if="1==0">
        <v-icon class="mr-1 white--text" @click="download()">cloud_download</v-icon>
      </v-col>
      <v-col cols="auto">
        <v-icon class="mr-1 white--text" @click="download()">print</v-icon>
      </v-col>
    </v-row>
    <pdf
      ref="pdf"
      class="pdf-content"
      :src="src"
      :page="page"
      :rotate="rotate"
      @loaded="loaded"
      @error="error"
      @num-pages="numPages = $event"
      @link-clicked="page = $event"
    ></pdf>
  </div>
</template>

<script>
import pdf from "vue-pdf";
import { getDetalleCalculo } from '@/api/core'

export default {
  name: "viewer-pdf",

  components: {
    pdf,
  },

  props: {
    id: {
      type: [String, Number],
    },
    content: {
      type: Uint8Array,
    },
  },

  data() {
    return {
      loading: true,
      page: 1,
      src: null,
      loadedRatio: 0,
      numPages: 0,
      rotate: 0,
      url: false
    };
  },

  created() {
    this.src = pdf.createLoadingTask(new Uint8Array(this.content));
  },

  watch: {},

  computed: {},

  mounted() {
    this.fetchUrl()
  },

  methods: {
    async fetchUrl() {
      const data = await getDetalleCalculo(this.id, 0)

      this.url = data.url
    },

    error: function (err) {
      console.log(err);
      this.$emit("error");
    },

    loaded: function () {
      this.$emit("loaded");
    },

    before: function () {
      if (this.page > 1) {
        this.page--;
      } else {
        this.page = 1;
      }
    },

    next: function () {
      if (this.page < this.numPages) {
        this.page++;
      } else {
        this.page = this.numPages;
      }
    },

    download: function () {
      if(this.url)
        window.open(this.url, "_blank");
    },

    print: function() {
      alert('Investigando si se puede enviar a imprimir directamente')
    }
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.pdf-page-input {
  max-width: 3rem;
  border-radius: 0.5rem;
  background-color: white;
  color: #222;
  text-align: center;
}

.pdf-content {
  box-shadow: 0px 10px 30px rgba(209, 213, 223, 0.5) !important;
  border-radius: 20px 20px 0px 20px !important;
  border: none;
}
</style>

<style>
.pdf-content canvas {
  border-radius: inherit !important;
}
</style>
